import { createApp } from 'vue';
import { setupStore } from '@/store';
import { setupDirective } from '@/directive';
import { setupComponents } from '@/components/index';
import App from './App.vue';
import router from './router';
import '@/style/base.less'; // 全局公共css
import '@/assets/fonts/iconfont.css';
import 'virtual:svg-icons-register';

// 获取所有 svg-icon name
// import ids from 'virtual:svg-icons-names';
// console.log(ids);

const app = createApp(App);
setupStore(app);
setupDirective(app);
setupComponents(app);

app.config.performance = true;
app.use(router);

app.mount('#app');


import '@/router/route-auth';


