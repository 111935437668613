import { createRouter, createWebHashHistory, RouteRecordRaw, createWebHistory } from 'vue-router';
import Layout from '@/components/layout/index.vue';

export const routes = [
  {
    path: '/login',
    component: () => import('@/views/login/login.vue'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/404.vue'),
    hidden: true
  },
  {
    path: '/ebook',
    component: () => import('@/views/ebook/read.vue'),
    hidden: true
  },
  {
    path: '/book/list',
    component: () => import('@/views/cms/book/read/list.vue'),
    hidden: true
  },
  {
    path: '/book/section',
    component: () => import('@/views/cms/book/read/section.vue'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    meta: { title: '仪表盘', icon: 'icon-configure', affix: true },
    alwaysShow: true,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/dashboard.vue'),
        meta: { title: '仪表盘', icon: 'icon-configure', affix: true }
      },
    ]
  }
];

const router = createRouter({
  history: createWebHistory(), // history 模式则使用 createWebHistory()
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      let top = 0;
      if (to.path === '/book/list') {
        const localTop = localStorage.getItem('list_scroll_top');
        if(localTop) top = Number(localTop);
      }
      setTimeout(() => {
        resolve({ top });
      }, 500);
    });
  },
});

export default router;
