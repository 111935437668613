import { store } from "@/store";
import { useUserStore } from "@/store/modules/user";
import { App } from "vue";

const userStore = useUserStore(store);
export function setupDirective(app: App<Element>) {
  app.directive('action', (el, bind) => {
    let activeActions = [];
    // 以 , 分割，可能一个按钮会需要多个权限
    if (bind.value) activeActions = bind.value.split(',');
    let flag = false;
    for (const action of activeActions) {
      // 判断权限是否存在，有不存在的则跳出循环
      if (!userStore.actions.includes(action)) {
        flag = true;
        break;
      }
    }
    if (flag) {
      el.setAttribute('disabled', 'disabled');
      el.classList.add("is-disabled");
    }
  });
}
