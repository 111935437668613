<!--
 svg-icon 组件
 @余柯南 20220829 22.56
 -->
<template>
  <svg aria-hidden="true" :width="WIDTH" :height="HEIGHT">
    <use :xlink:href="symbolId" :fill="COLOR" />
  </svg>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';

export default defineComponent({
  name: 'SvgIcon',
  props: {
    prefix: {
      type: String,
      default: 'icon',
    },
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '#333',
    },
    width: {
      type: Number,
      default: 25
    },
    height: {
      type: Number,
      default: 25
    }
  },
  setup(props) {
    const symbolId = computed(() => `#${props.prefix}-${props.name}`);
    const WIDTH = ref(props.width);
    const HEIGHT = ref(props.height);
    const COLOR = ref(props.color);
    console.log(COLOR.value);

    return { symbolId, WIDTH, HEIGHT, COLOR };
  },
});
</script>
