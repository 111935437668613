import { request, Response } from "@/utils/http";

// 获取当前配置
export const defaultConfig = (mode:string) => {
  return request<Response>({
    method: 'get',
    url: `/api/v1/setting/${mode}`,
  });
};

// 获取配置列表
export const configList = () => {
  return request<Response>({
    method: 'get',
    url: `/api/v1/setting`,
  });
};
