<!--
 uk-btn 组件
 @余柯南 20220829 23.21
 -->
<template>
  <el-button :size="SIZE" :type="TYPE" :plain="PLAIN" :text="TEXT" :bg="BG" :link="LINK" :round="ROUND" :circle="CIRCLE"
    :disabled="DISABLED" :autofocus="AUTOFOCUS" :auto-insert-space="AUTOINSERTSPACE" :native-type="NATIVETYPE">
    <svg-icon v-if="ICON" :class="LOADING ? 'loading' : ''" :name="ICON" :width="ICONWIDTH" :color="ICONCOLOR"
      :height="ICONHEIGHT" />
    <slot style="margin-left: 4px;" />
  </el-button>
</template>

<script lang='ts'>
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
  name: 'UkBtn',
  props: {
    size: {
      type: String,
      default: 'default'
    },
    type: {
      type: String,
      default: ''
    },
    plain: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    bg: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    autoInsertSpace: {
      type: Boolean,
      default: true
    },
    loadingIcon: {
      type: String,
      default: 'loading'
    },
    nativeType: {
      type: String,
      default: 'button'
    },
    icon: {
      type: String,
      default: ''
    },
    iconWidth: {
      type: String,
      default: '20px'
    },
    iconHeight: {
      type: String,
      default: '20px'
    },
  },
  setup(props) {
    const SIZE = ref<any>(props.size);
    const TYPE = ref<any>(props.type);
    const PLAIN = ref<any>(props.plain);
    const TEXT = ref<any>(props.text);
    const BG = ref<any>(props.bg);
    const LINK = ref<any>(props.link);
    const ROUND = ref<any>(props.round);
    const CIRCLE = ref<any>(props.circle);
    const LOADING = ref<boolean>(props.loading);
    const DISABLED = ref<any>(props.disabled);
    const AUTOFOCUS = ref<any>(props.autofocus);
    const AUTOINSERTSPACE = ref<boolean>(props.autoInsertSpace);
    const LOADINGICON = ref<any>(props.loadingIcon);
    const NATIVETYPE = ref<any>(props.nativeType);
    const ICON = ref<string>(props.icon);
    const ICONWIDTH = ref<any>(props.iconWidth);
    const ICONHEIGHT = ref<any>(props.iconHeight);
    const ICONCOLOR = ref<any>(props.type === '' ? '#606266' : '#fff');

    watch(
      () => props.loading,
      (nv, ov) => {
        if (nv) {
          LOADING.value = true;
          ICON.value = LOADINGICON.value;
        }
        if (ov) {
          LOADING.value = false;
          ICON.value = '';
        }
      }
    );

    return {
      SIZE, TYPE, PLAIN, TEXT, BG, LINK, ROUND, CIRCLE, LOADING, DISABLED, AUTOFOCUS,
      AUTOINSERTSPACE, LOADINGICON, NATIVETYPE, ICON, ICONWIDTH, ICONHEIGHT, ICONCOLOR
    };
  }
});
</script>

<style lang='less' scoped>
.loading {
  animation: rotating 2s linear infinite;
}
</style>
